import * as React from "react";
import * as styles from "./layout.module.scss";
import Header from "../Header/Header"
import { useEffect, useState } from "react"
import { waitForPageLoad } from "../../services/preloadingService"
import LoaderScreen from "../LoaderScreen/LoaderScreen"
import Head from "../Head/head"
import CookiesBar from "../CookiesBar/CookiesBar"

type Props = {
  children: React.ReactNode;
  imagesToPreload?: String[];
  onLoadCallback?: () => void;
}

const Layout: React.FC<Props> = ({ children, imagesToPreload, onLoadCallback}: Props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    waitForPageLoad(imagesToPreload, () => {
      setLoading(false);
      onLoadCallback && onLoadCallback();
    })
  }, [])

  if (loading) {
    return (
      <LoaderScreen />
    )
  }

  return (
    <div className={styles.container}>
      <Head />
      <Header />
      {children}
      <CookiesBar />
    </div>
  )
}

export default Layout;
