import * as React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";
import * as styles from "./mobileMenu.module.scss";
import { NAME } from "../../consts/common"
import logo from '../../../static/logo/logo_black.svg';
import { CgClose } from "@react-icons/all-files/cg/CgClose"
import { useEffect, useRef, useState } from "react"
import CustomAnchorLink from "../CustomAnchorLink/CustomAnchorLink"
import useOnClickOutside from "../../helpers/useOnClickOutside"

type Props = {
  onClose: () => void,
  shouldShow: boolean,
  items: {
    about: string,
    gallery: string,
    offer: string,
    offerItems: {
      name: string,
      slug: string,
    }[],
    regulations: string,
  }
}

const MobileMenu: React.FC<Props> = ({ onClose, shouldShow, items }: Props) => {
  const [shouldRender, setShouldRender] = useState(shouldShow);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    shouldShow && setShouldRender(true);
  }, [shouldShow])

  useOnClickOutside(mobileMenuRef, () => {
    shouldRender && onClose();
  });

  const onAnimationEnd = () => {
    !shouldShow && setShouldRender(false);
  }

  const intl = useIntl();
  const {locale} = intl;

  if (shouldRender) {
    return (
      <div
        style={{ animation: `${shouldShow ? 'menuSlideIn' : 'menuSlideOut'} 0.7s`}}
        className={styles.mobileMenu}
        onAnimationEnd={onAnimationEnd}
        ref={mobileMenuRef}
      >
        <CgClose onClick={onClose} className={styles.closeIcon}/>
        <Link style={{marginBottom: '30px'}} to="/"><img src={logo} alt={NAME} height="60" width="154" /></Link>
        <Link
          className={styles.mobileLink__base}
          to="/about/"
          activeClassName={styles.mobileLink__active}
          onClick={onClose}
        >
          {items.about}
        </Link>
        <Link
          className={styles.mobileLink__base}
          to="/gallery/"
          activeClassName={styles.mobileLink__active}
          onClick={onClose}
        >
          {items.gallery}
        </Link>
        <Link
          className={styles.mobileLink__base}
          to="/offer/"
          getProps={(props) => props.location.pathname.includes('/offer') ? {className: `${styles.mobileLink__base} ${styles.mobileLink__active}`} : {}}
          onClick={onClose}
        >
          {items.offer}
        </Link>
        { items.offerItems.map(item => (
          <CustomAnchorLink
            key={item.slug}
            className={styles.mobileLink__sublink}
            to={`/offer#${item.slug}`}
            language={locale}
            onAnchorLinkClick={onClose}
            // stripHash
          >
            {item.name}
          </CustomAnchorLink>
        ))}
        <Link
          className={styles.mobileLink__base}
          to="/contact/"
          activeClassName={styles.mobileLink__active}
          onClick={onClose}
        >
          {intl.formatMessage({id: 'header__contact'})}
        </Link>
        <Link
          className={styles.mobileLink__base}
          to="/regulations/"
          activeClassName={styles.mobileLink__active}
          onClick={onClose}
        >
          {items.regulations}
        </Link>
      </div>
    )
  }
  return null;
}

export default MobileMenu;
