import * as React from "react";
import logo from '../../../static/logo/logo_white.svg';
import * as modalStyles from '../CalendarModal/calendarModal.module.scss';
import * as styles from './loaderScreen.module.scss';

const LoaderScreen: React.FC = () => (
  <div className={modalStyles.modalContainer}>
    <img width={200} src={logo} />
    <div className={styles.ldsEllipsis}>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
)

export default LoaderScreen;
