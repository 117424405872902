import * as React from "react";
import { IntlContextConsumer } from "gatsby-plugin-intl"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const CustomAnchorLink = ({ to, language, children, onAnchorLinkClick, ...rest }) => (
  <IntlContextConsumer>
    {intl => {
      const languageLink = language || intl.language
      const link = intl.routed || language ? `/${languageLink}${to}` : `${to}`

      const handleClick = () => {
        if (language) {
          window.localStorage.setItem('gatsby-intl-language', language)
        }
        if (onAnchorLinkClick) {
          onAnchorLinkClick()
        }
      }

      return (
        <AnchorLink {...rest} to={link} onAnchorLinkClick={handleClick}>
          {children}
        </AnchorLink>
      )
    }}
  </IntlContextConsumer>
)

export default CustomAnchorLink