import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"

const Head = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const {title, description} = data.site.siteMetadata;

  return (
    <Helmet title={data.site.siteMetadata.title}>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default Head
