import * as React from "react";
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl"
import MobileMenu from "../MobileMenu/MobileMenu"
import { NAME } from "../../consts/common"
import * as styles from "./header.module.scss";
import logo from "../../../static/logo/logo_black.svg"
import { FiMenu } from "@react-icons/all-files/fi/FiMenu"
import linkPrettier from "../../helpers/linkPrettier"
import CustomAnchorLink from "../CustomAnchorLink/CustomAnchorLink"
import { createLocalePredicate } from "../../helpers/LocalesHelper";

const menuQuery = graphql`
  query {
    allContentfulOffersSet(filter: { slug: { eq: "main" } }) {
      nodes {
        name
        node_locale
        apartmentsHeader
        apartmentsSlug
        offerItems {
          title
          slug
          node_locale
        }
      }
    }
    allContentfulGallery(filter: { galleryId: { eq: "1" } }) {
      nodes {
        node_locale
        title
      }
    }
    allContentfulStaticPage(filter: { slug: { in: ["about", "regulations"] } }) {
      nodes {
        slug
        header
        node_locale
      }
    }
  }
`;

function getOfferHeader(
  offersData: { nodes: {node_locale: string, name: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return offersData
    .nodes
    .filter(localePredicate)
    .map(node => node.name)[0]
}

function getAppartmentsHeader(
  offersData: { nodes: { node_locale: string, apartmentsHeader: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return offersData
    .nodes
    .filter(localePredicate)
    .map(node => node.apartmentsHeader)[0]
}

function getAppartmentsSlug(
  offersData: { nodes: { node_locale: string, apartmentsSlug: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return offersData
    .nodes
    .filter(localePredicate)
    .map(node => node.apartmentsSlug)[0]
}

function getOffersNames(
  offersData: { nodes: { offerItems: { node_locale: string, title: string, slug: string}[] }[] },
  localePredicate: (item: { node_locale: string}) => boolean
): { name: string, slug: string }[] {
  return offersData
    .nodes
    .flatMap(node => node.offerItems)
    .filter(localePredicate)
    .map(item => ({ name: item.title, slug: item.slug }))
}

function getGalleryName(
  galleryData: { nodes: { node_locale: string, title: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return galleryData
    .nodes
    .filter(localePredicate)
    .map(node => node.title)[0]
}

function getStaticPageHeader(
  staticPagesData: { nodes: { node_locale: string, slug: string, header: string }[] },
  slug: string,
  localePredicate: (item: { node_locale: string }) => boolean): string {
  return staticPagesData
    .nodes
    .filter(node => node.slug === slug)
    .filter(localePredicate)
    .map(node => node.header)[0]
  }

function getRegulationsHeader(
  staticPagesData: { nodes: { node_locale: string, slug: string, header: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return getStaticPageHeader(staticPagesData, "regulations", localePredicate);
}

function getAboutUsHeader(
  staticPagesData: { nodes: { node_locale: string, slug: string, header: string }[] },
  localePredicate: (item: { node_locale: string }) => boolean
): string {
  return getStaticPageHeader(staticPagesData, "about", localePredicate);
}

const Header: React.FC = (props) => {
  const menuData = useStaticQuery(menuQuery)

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const intl = useIntl()
  const {locale} = intl;

  const currentLocalePredicate = createLocalePredicate(locale);
  
  const aboutHeader = getAboutUsHeader(menuData.allContentfulStaticPage, currentLocalePredicate);
  const galleryName = getGalleryName(menuData.allContentfulGallery, currentLocalePredicate);
  const offerHeader = getOfferHeader(menuData.allContentfulOffersSet, currentLocalePredicate);
  const apartmentsHeader = getAppartmentsHeader(menuData.allContentfulOffersSet, currentLocalePredicate);
  const apartmentsSlug = getAppartmentsSlug(menuData.allContentfulOffersSet, currentLocalePredicate);
  const offerItems = [
    { name: apartmentsHeader, slug: apartmentsSlug },
    ...getOffersNames(menuData.allContentfulOffersSet, currentLocalePredicate)
  ];
  const regulationsHeader = getRegulationsHeader(menuData.allContentfulStaticPage, currentLocalePredicate);

  return (
    <div className={styles.header}>
      <Link to="/"><img src={logo} alt={NAME} height="60" width="154" /></Link>
      <div className={styles.menuItems}>
        <Link className={styles.link__base} to="/about/" activeClassName={styles.link__active}>{aboutHeader}</Link>
        <Link className={styles.link__base} to="/gallery/" activeClassName={styles.link__active}>{galleryName}</Link>
        <div className={styles.dropdown}>
          <Link
            to="/offer/"
            className={styles.link__base}
            getProps={(props) => props.location.pathname.includes('/offer') ? {className: `${styles.link__base} ${styles.link__active}`} : {}}
          >
            {offerHeader}
          </Link>
          <div className={styles.dropdownItems}>
            { offerItems.map(item => (
              <CustomAnchorLink
                key={item.slug}
                className={styles.link__sublink}
                to={`/offer#${item.slug}`}
                language={locale}
                // stripHash
              >
                {item.name}
              </CustomAnchorLink>
            ))}
          </div>
        </div>


        <Link className={styles.link__base} to="/contact/" activeClassName={styles.link__active}>{intl.formatMessage({id: 'header__contact'})}</Link>
        <Link className={styles.link__base} to="/regulations/" activeClassName={styles.link__active}>{regulationsHeader}</Link>
      </div>
      <FiMenu onClick={() => {setMobileMenuVisible(true)}} className={styles.menuIcon} />
      <MobileMenu
        shouldShow={mobileMenuVisible}
        onClose={() => {setMobileMenuVisible(false)}}
        items={{ about: aboutHeader, gallery: galleryName, offer: offerHeader, offerItems: offerItems, regulations: regulationsHeader }} />
    </div>
  )
}

export default Header;
