import * as React from "react";
import * as styles from "./cookiesBar.module.scss";
import CookieConsent from "react-cookie-consent"
import { Link, useIntl } from "gatsby-plugin-intl"
import { GiCookie } from "@react-icons/all-files/gi/GiCookie"

const CookiesBar: React.FC = () => {
  const intl = useIntl();
  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({id: 'cookies__accept'})}
      containerClasses={styles.cookiesBar__container}
      buttonClasses={styles.cookiesBar__button}
      contentClasses={styles.cookiesBar__content}
    >
      <GiCookie className={styles.cookiesBar__icon} />
      <div style={{width: '90%'}}>
        <div>
          {intl.formatMessage({id: 'cookies__description'})}
        </div>
        <div className={styles.cookiesBar__link}>
          <Link className={styles.cookiesBar__link} to="/privacyPolicy/">{intl.formatMessage({ id: 'cookies__privacy-policy-link' })}</Link>
        </div>
      </div>

    </CookieConsent>
  )
}

export default CookiesBar
