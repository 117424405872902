// extracted by mini-css-extract-plugin
export const modalContainer = "calendarModal-module--modalContainer--1zO_g";
export const bigCalendar = "calendarModal-module--bigCalendar--3RiVW";
export const smallCalendar = "calendarModal-module--smallCalendar--2jA71";
export const toolbar__row = "calendarModal-module--toolbar__row--2vRR8";
export const toolbar__rowSpaceBetween = "calendarModal-module--toolbar__row--spaceBetween--1MHkm";
export const toolbar__rowJustifyStart = "calendarModal-module--toolbar__row--justifyStart--1Eink";
export const toolbar__label = "calendarModal-module--toolbar__label--ED05W";
export const toolbar__arrow = "calendarModal-module--toolbar__arrow--1_lBY";
export const toolbar__apartmentItem = "calendarModal-module--toolbar__apartmentItem--16msu";
export const toolbar__colorItem = "calendarModal-module--toolbar__colorItem--3dC0E";