export const loadImage = (url: String) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image.src);
    };
    image.onerror = () => {
      window.console.error(`Error in ${url} file!`);
      resolve(null);
    };
    image.src = url;
  })
}

export const checkIfFontsLoaded = () => {
  return new Promise((resolve) => {
    if (typeof document !== 'undefined') {
      document.fonts && document.fonts.ready
        .then(() => {
          resolve();
        })
    } else {
      resolve();
    }
  })
}

export const loadImages = (urlArray: String[]) => {
  const preloadedImagesArray = [];
  return new Promise(resolve => {
    urlArray.forEach(url => {
      loadImage(url).then((imgUrl) => {
        preloadedImagesArray.push(imgUrl);
        if (preloadedImagesArray.length === urlArray.length) {
          resolve(preloadedImagesArray);
        }
      });
    });
  });
}

export const waitForPageLoad = (imagesArray: String[], onLoadCallback: () => void) => {
  const promisesArr = [checkIfFontsLoaded()];
  imagesArray && imagesArray.length > 0 && promisesArr.push(loadImages(imagesArray))
  Promise.all(promisesArr)
    .then(() => { onLoadCallback(); })
}
